import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  public readonly apiUrl = environment.API_BASE_URL;
  constructor(public http: HttpClient) {
    // set token if saved in local storage
  }

  // Create New Offer
  createOffer(offerBody: Object) {
    return this.http.post(`${this.apiUrl}offers`, offerBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get Offer by nft
  getOfferByNft(nft: string) {
    return this.http.get(`${this.apiUrl}offers/nft/${nft}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get Offer by offerId
  getOfferById(offerId: string) {
    return this.http.get(`${this.apiUrl}offers/${offerId}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // delete Offer by offerId
  deletOffer(offerId: string) {
    return this.http.delete(`${this.apiUrl}offers/${offerId}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
