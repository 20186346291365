import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OwnerService {
  public readonly apiUrl = environment.API_BASE_URL;
  constructor(public http: HttpClient) {
    // set token if saved in local storage
  }

  setActiveOwner(ownerBody: Object) {
    return this.http
      .patch(`${this.apiUrl}owners/set-active-owner`, ownerBody)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getActiveOwner(nftId: string) {
    return this.http.get(`${this.apiUrl}owners/active-owner/${nftId}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
