import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BidService {
  public readonly apiUrl = environment.API_BASE_URL;
  constructor(public http: HttpClient) {}

  // Create New Bid
  createBid(bidBody: Object) {
    return this.http.post(`${this.apiUrl}bids`, bidBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get Bid by nft
  getBidByNft(nft: string) {
    return this.http.get(`${this.apiUrl}bids/nft/${nft}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get all Bid by nft
  getAllBidByNft(nft: string) {
    return this.http.get(`${this.apiUrl}bids/nft/all/${nft}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get lastest Bid by nft
  getLatestBidByNft(nft: string) {
    return this.http.get(`${this.apiUrl}bids/latest-bid/${nft}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  // get lastest Bid by nft
  deleteBidById(bidId: string) {
    return this.http.delete(`${this.apiUrl}bids/${bidId}`).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }
}
