		<div class="container">
			<div class="row row--grid">
			

				<!-- error -->
				<div class="col-12">
					<div class="page-404">
						<div class="page-404__wrap">
							<div class="page-404__content">
								<h1 class="page-404__title">404</h1>
								<p class="page-404__text">The page you are looking for not available!</p>
								<a [routerLink]="['/']" routerLinkActive="router-link-active"  class="page-404__btn">go back</a>
							</div>
						</div>
					</div>
				</div>
				<!-- end error -->
			</div>
		</div>